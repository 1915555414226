/* You can add global styles to this file, and also import other style files */
@import "~assets/css/Ag-grid-css/ag-grid.css";
@import "~assets/css/Ag-grid-css/ag-theme-balham.css";
//@import 'ag-grid-community/styles/ag-grid.css';
//@import '~ag-grid-community/styles/ag-theme-balham.css';

@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";


.cal-events {
    display: none !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ag-theme-material .sick {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.rowBackgroundColor {
  background-color: lightgrey !important;
}

.custom-dialog-container-materialFulfilment .mat-dialog-container {
  padding: 0px !important;
  overflow: hidden;
  height: fit-content;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}


.quicksight-embedding-iframe{
  width: 100% !important;
  height: 100% !important;
}

.quicksight-embedding-iframe iframe{
  width: 100% !important;
  height: 100% !important;
}

iframe{
  width: 100% !important;
  height: 100% !important;
}
input[type='password']::-ms-reveal {display : none ;}
