.custom-container {
  display: block;
  margin: 0 20px 20px;
  /* padding: 20px; */
}

.cronjobselector {
  padding: 0 0 20px 0;
  /* background: #e7e7e7; */
}


.cronjobselector select:disabled {
  background: #ccc;
}
.cronjobselector .timesAndDates ul li,
.cronjobselector .timesAndDates ul {
  list-style: none;
  margin: 0;
  /* padding: 5px 15px;
  padding-right: 50px; */
  padding-left: 0;
}
